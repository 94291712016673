<template>
  <div>
    <div class="app-header">
      <img alt="logo" :src="logoPath"/>
    </div>
    <div class="center">
      <b-container class="msg" v-if="status">
        <p>
          {{ $t('validationEmail.message') }}
        </p>
        <b-button
          type="button"
          variant="outline-primary"
          @click.prevent="$router.push({ name: 'SignIn' })"
        >
          {{ $t('validationEmail.goToLogin') }}
        </b-button>
      </b-container>
      <b-container class="msg" v-else>
        <p>
          {{ $t('validationEmail.error') }}
        </p>
      </b-container>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
} from 'vuex';

export default {
  name: 'ValidationEmail',
  data: () => {
    return {
    };
  },
  computed: {
    ...mapState('validation-email', [
      'status'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },
  created() {
    this.POST_TOKEN(this.$route.query.token);
  },
  methods: {
    ...mapActions('validation-email', [
      'POST_TOKEN'
    ])
  }
};
</script>

<style lang="less" scoped>

.center {
  position: initial;
}

.center .msg.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center .msg.container,
.center .msg.container button {
  font-size: large;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
